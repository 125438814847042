import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import swapCmsUrlWithCdn from '../utils/swapCmsUrlWithCdn'
import useSeo from '../hooks/useSeo'

import useSiteMetadata from '../hooks/use-site-metadata'

const StructuredData = props => {
  const { pageType, post } = props

  // Get site query from our hook
  const { siteUrl, title: siteTitle } = useSiteMetadata()

  const { social } = useSeo()
  const twitterUrl = `https://twitter.com/${social.twitter.username}`
  const facebookUrl = social?.facebook?.url
  const youtubeUrl = social?.youTube?.url
  const linkedInUrl = social?.linkedIn?.url
  const instagramUrl = social?.instagram?.url
  const opengraphImage = social?.facebook?.defaultImage

  const inLanguage = 'en-US'

  const context = {
    '@context': 'https://schema.org',
  }

  const organization = {
    ...context,
    '@type': 'Organization',
    '@id': `${siteUrl}/#organization`,
    name: siteTitle,
    url: siteUrl,
    logo: {
      '@type': 'ImageObject',
      '@id': `${siteUrl}/#logo`,
      url: `${siteUrl}/default-open-graph.png`,
      inLanguage,
      width: 250,
      height: 177,
      caption: 'Liquid Web',
    },
    sameAs: [facebookUrl, youtubeUrl, instagramUrl, linkedInUrl, twitterUrl],
  }

  const headelement = {
    ...context,
    '@type': 'WPHeader',
    '@id': post
      ? `${siteUrl}${post.slug}/#site-header`
      : `${siteUrl}/#site-header`,
    url: siteUrl,
  }

  const navelement1 = {
    ...context,
    '@type': 'SiteNavigationElement',
    '@id': post
      ? `${siteUrl}${post.slug}/#nav-primary-mobile`
      : `${siteUrl}/#nav-primary-mobile`,
  }

  const navelement2 = {
    ...context,
    '@type': 'SiteNavigationElement',
  }

  const schema = [{}]

  if (pageType === 'webPage' && post) {
    schema.push({
      ...context,
      '@graph': [
        {
          ...organization,
        },
        {
          ...context,
          '@type': 'WebPage',
          '@id': `${siteUrl}${post.slug}/#webpage`,
          url: `${siteUrl}${post.slug}`,
          name: post.title,
          description: post.seo.metaDesc,
          datePublished: post.date,
          dateModified: post.modified,
          inLanguage,
          isPartOf: {
            '@type': 'WebSite',
            '@id': `${siteUrl}/#website`,
            url: `${siteUrl}`,
            name: 'Liquid Web',
            inLanguage,
            publisher: organization,
            potentialAction: {
              '@type': 'SearchAction',
              target: {
                '@type': 'EntryPoint',
                urlTemplate: `${siteUrl}/search/?q={search_term_string}`,
              },
              'query-input': {
                '@type': 'PropertyValueSpecification',
                valueRequired: 'http://schema.org/True',
                valueName: 'search_term_string',
              },
            },
          },
          primaryImageOfPage: {
            '@type': 'ImageObject',
            '@id': `${siteUrl}${post.slug}/#primaryimage`,
            inLanguage,
            url: swapCmsUrlWithCdn(
              post?.seo?.opengraphImage?.sourceUrl ?? opengraphImage?.sourceUrl
            ),
          },
          potentialAction: {
            '@type': 'ReadAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: `${siteUrl}${post.slug}`,
            },
          },
        },
        {
          ...navelement1,
        },
        {
          ...navelement2,
        },
        {
          ...headelement,
        },
      ],
    })
  }

  if (pageType === 'article') {
    schema.push({
      ...context,
      '@graph': [
        {
          ...organization,
        },
        {
          ...context,
          '@type': 'BlogPosting',
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': `${siteUrl}/blog/${post.slug}`,
          },
          headline: post.title,
          description: post.seo.metaDesc,
          image:
            post.featuredImage &&
            post.featuredImage.node &&
            post.featuredImage.node.localFile
              ? `${siteUrl}${post.featuredImage.node.localFile.publicURL}`
              : null,
          author: {
            '@type': 'Person',
            name: post.author?.node?.name ?? siteTitle,
            url: post.author?.node?.uri ?? siteTitle,
          },
          publisher: {
            '@type': 'Organization',
            name: 'Liquid Web',
            logo: {
              '@type': 'ImageObject',
              url: `${siteUrl}/default-open-graph.png`,
            },
          },
          datePublished: post.date,
          dateModified: post.modified,
        },
        {
          ...navelement1,
        },
        {
          ...navelement2,
        },
        {
          ...headelement,
        },
      ],
    })
  }

  if (pageType === 'kbArticle') {
    schema.push({
      ...context,
      '@graph': [
        {
          ...organization,
        },
        {
          '@type': 'Article',
          '@id': `${siteUrl}/kb/${post.slug}/#article`,
          headline: post.title,
          description: post.excerpt,
          datePublished: post.date,
          dateModified: post.modified,
          image:
            post.featuredImage &&
            post.featuredImage.node &&
            post.featuredImage.node.localFile
              ? `${siteUrl}${post.featuredImage.node.localFile.publicURL}`
              : null,
          author: {
            '@type': 'Person',
            name: post.author?.node?.name ?? siteTitle,
            description: post.author?.node?.description ?? siteTitle,
          },
          publisher: { ...organization },
          mainEntityOfPage: `${siteUrl}/kb/${post.slug}`,
        },
        {
          ...navelement1,
        },
        {
          ...navelement2,
        },
        {
          ...headelement,
        },
      ],
    })
  }

  if (pageType === 'resource') {
    const category = post?.categories?.nodes[0]?.slug
    schema.push({
      ...context,
      '@graph': [
        {
          ...organization,
        },
        {
          '@type': 'Article',
          '@id': `${siteUrl}/resources/${category}/${post.slug}/#article`,
          headline: post.title,
          description: post.lwExcerpt,
          datePublished: post.date,
          dateModified: post.modified,
          image:
            post.featuredImage &&
            post.featuredImage.node &&
            post.featuredImage.node.localFile
              ? `${siteUrl}${post.featuredImage.node.localFile.publicURL}`
              : null,
          publisher: { ...organization },
          mainEntityOfPage: `${siteUrl}/resources/${category}/${post.slug}`,
        },
        {
          ...navelement1,
        },
        {
          ...navelement2,
        },
        {
          ...headelement,
        },
      ],
    })
  }

  if (pageType === 'categoriesTags') {
    schema.push({
      ...context,
      '@graph': [
        {
          ...organization,
        },
        {
          '@type': 'CollectionPage',
          '@id': `${siteUrl}${post.slug}/#webpage`,
          name: post.title,
          description: post.seo.metaDesc,
          inLanguage,
          isPartOf: {
            '@type': 'WebSite',
            '@id': `${siteUrl}/blog/#website`,
            url: `${siteUrl}/blog/`,
            name: 'Liquid Web',
            inLanguage,
            publisher: organization,
            potentialAction: {
              '@type': 'SearchAction',
              target: {
                '@type': 'EntryPoint',
                urlTemplate: `${siteUrl}/search/?q={search_term_string}`,
              },
              'query-input': {
                '@type': 'PropertyValueSpecification',
                valueRequired: 'http://schema.org/True',
                valueName: 'search_term_string',
              },
            },
          },
        },
        {
          ...navelement1,
        },
        {
          ...navelement2,
        },
        {
          ...headelement,
        },
      ],
    })
  }

  // also being used by kbTags and kbCategories
  if (pageType === 'kbSeries') {
    schema.push({
      ...context,
      '@graph': [
        {
          ...organization,
        },
        {
          '@type': 'CollectionPage',
          '@id': `${siteUrl}${post.slug}/#webpage`,
          name: post.title,
          description: post.seo.metaDesc,
          inLanguage,
          isPartOf: {
            '@type': 'WebSite',
            '@id': `${siteUrl}/kb/#website`,
            url: `${siteUrl}/kb/`,
            name: 'Liquid Web',
            inLanguage,
            publisher: organization,
            potentialAction: {
              '@type': 'SearchAction',
              target: {
                '@type': 'EntryPoint',
                urlTemplate: `${siteUrl}/search/?q={search_term_string}`,
              },
              'query-input': {
                '@type': 'PropertyValueSpecification',
                valueRequired: 'http://schema.org/True',
                valueName: 'search_term_string',
              },
            },
          },
        },
        {
          ...navelement1,
        },
        {
          ...navelement2,
        },
        {
          ...headelement,
        },
      ],
    })
  }

  if (pageType === 'author') {
    schema.push({
      ...context,
      '@graph': [
        {
          ...organization,
        },
        {
          '@type': 'Person',
          '@id': `${siteUrl}${post.slug}/#/schema/person/1720b66e514de3095069e9eaaff74230`,
          name: post.author?.node?.name ?? siteTitle,
          description: post.author?.node?.description ?? siteTitle,
          mainEntityOfPage: {
            '@type': 'ProfilePage',
            '@id': '{siteUrl}${post.slug}/#webpage',
            url: `${siteUrl}${post.slug}`,
            name: post.author?.node?.name ?? siteTitle,
            description: post.author?.node?.description ?? siteTitle,
            inLanguage,
            isPartOf: {
              '@type': 'WebSite',
              '@id': `${siteUrl}/blog/#website`,
              url: `${siteUrl}/blog/`,
              name: 'Liquid Web',
              inLanguage,
              publisher: organization,
              potentialAction: {
                '@type': 'SearchAction',
                target: {
                  '@type': 'EntryPoint',
                  urlTemplate: `${siteUrl}/search/?q={search_term_string}`,
                },
                'query-input': {
                  '@type': 'PropertyValueSpecification',
                  valueRequired: 'http://schema.org/True',
                  valueName: 'search_term_string',
                },
              },
            },
          },
          potentialAction: {
            '@type': 'ReadAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: `${siteUrl}${post.slug}`,
            },
          },
        },
        {
          ...navelement1,
        },
        {
          ...navelement2,
        },
        {
          ...headelement,
        },
      ],
    })
  }

  if (pageType === 'kbAuthor') {
    schema.push({
      ...context,
      '@graph': [
        {
          ...organization,
        },
        {
          '@type': 'Person',
          '@id': `${siteUrl}${post.slug}/kb/#/schema/person/6dd0c085ab1a63421b8963f02689deb6`,
          name: post.author?.node?.name ?? siteTitle,
          description: post.author?.node?.description ?? siteTitle,
          mainEntityOfPage: {
            '@type': 'ProfilePage',
            '@id': '{siteUrl}${post.slug}/#webpage',
            url: `${siteUrl}${post.slug}`,
            name: post.author?.node?.name ?? siteTitle,
            description: post.author?.node?.description ?? siteTitle,
            inLanguage,
            isPartOf: {
              '@type': 'WebSite',
              '@id': `${siteUrl}/kb/#website`,
              url: `${siteUrl}/kb/`,
              name: 'Liquid Web',
              inLanguage,
              publisher: organization,
              potentialAction: {
                '@type': 'SearchAction',
                target: {
                  '@type': 'EntryPoint',
                  urlTemplate: `${siteUrl}/search/?q={search_term_string}`,
                },
                'query-input': {
                  '@type': 'PropertyValueSpecification',
                  valueRequired: 'http://schema.org/True',
                  valueName: 'search_term_string',
                },
              },
            },
          },
          potentialAction: {
            '@type': 'ReadAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: `${siteUrl}${post.slug}`,
            },
          },
        },
        {
          ...navelement1,
        },
        {
          ...navelement2,
        },
        {
          ...headelement,
        },
      ],
    })
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

StructuredData.propTypes = {
  pageType: PropTypes.oneOf([
    'article',
    'webPage',
    'kbArticle',
    'categoriesTags',
    'kbSeries',
    'author',
    'kbAuthor',
    'resource',
  ]),
  post: PropTypes.shape({
    author: PropTypes.shape({
      name: PropTypes.string,
      avatar: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    date: PropTypes.string,
    modified: PropTypes.string,
    title: PropTypes.string,
    excerpt: PropTypes.string,
    slug: PropTypes.string,
    featuredImage: PropTypes.shape({
      localFile: PropTypes.object,
    }),
  }),
}

export default StructuredData
